export default [
  {
    header: 'administration.navigation.marketings',
  },
  {
    title: 'administration.navigation.marketing',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'administration.navigation.coupons',
        route: 'coupons-index',
        action: 'index',
        resource: 'coupons',
      },
      {
        title: 'administration.navigation.reviews',
        route: 'reviews-index',
        action: 'index',
        resource: 'reviews',
      },
      {
        title: 'administration.navigation.feeds',
        route: 'feeds-index',
        action: 'index',
        resource: 'feeds',
      },
    ],
  },
]
